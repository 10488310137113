<template>
  <section id="hub-details">
    <b-row>
      <b-col
        xl="4"
        md="4"
      >
        <b-card>
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mb-1"
          >
            {{ hubDetails.name }}
          </b-card-title>
          <hr>
          <dl class="row">
            <dt class="col-sm-4">
              Orders close at
            </dt>
            <dd class="col-sm-8">
              {{ ordersCloseAtLocale }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              VAT ID
            </dt>
            <dd class="col-sm-8">
              <b-input-group>
                <b-form-input
                  v-model="hubDetails.vat_id"
                  @change="handleHubDetailsChange(hubDetails.id, 'vat_id', hubDetails.vat_id)"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary">
                    Save
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Tax number
            </dt>
            <dd class="col-sm-8">
              <b-input-group>
                <b-form-input
                  v-model="hubDetails.tax_number"
                  @change="handleHubDetailsChange(hubDetails.id, 'tax_number', hubDetails.tax_number)"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary">
                    Save
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Bank details
            </dt>
            <dd class="col-sm-8">
              <b-input-group>
                  <b-form-textarea
                  v-model="hubDetails.bank_info"
                  rows="3"
                  @change="handleHubDetailsChange(hubDetails.id, 'bank_info', hubDetails.bank_info)"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary">
                    Save
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Invoice prefix
            </dt>
            <dd class="col-sm-8">
              <b-input-group>
                <b-form-input
                  v-model="hubDetails.invoice_prefix"
                  @change="handleHubDetailsChange(hubDetails.id, 'invoice_prefix', hubDetails.invoice_prefix)"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary">
                    Save
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              SKU Duplicates Notification
            </dt>
            <dd class="col-sm-8">
              <b-form-checkbox
                class="custom-control-success"
                v-model="hubDetails.sku_duplicates_notification"
                :id="'sku-duplicates-notification-' + hubDetails.id"
                @change="handleHubDetailsChange(hubDetails.id, 'sku_duplicates_notification', hubDetails.sku_duplicates_notification)"
              >
              </b-form-checkbox>
            </dd>
          </dl>
        </b-card>
        <b-row>
          <b-col
            xl="12"
            md="12"
          >
            <b-card>
              <b-card-title
                title-tag="h3"
                class="font-weight-bold mb-1"
              >
                Available Processes
              </b-card-title>
              <hr>
              <div class="demo-inline-spacing">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="dark"
                  size="sm"
                  v-for="proc in availableProcesses"
                  :key="proc.id"
                  :id="'process-' + proc.id"
                  @click="handleCreateProcess(hubDetails.id, proc.id)"
                >
                  {{ proc.name }}
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        xl="8"
        md="8"
      >
        <b-row>
          <b-col
            xl="6"
            md="6"
            v-for="pc in hubDetails.process_configurations"
            :key="pc.id"
          >
            <b-card>
              <b-card-title
                title-tag="h3"
                class="font-weight-bold mb-1"
              >
                {{ pc.process_name }}
              </b-card-title>
              <hr>
              <div
                v-if="pc.process_name != 'Get orders analytics'"
              >
                <h4><b>When to perform</b></h4>
                <dl class="row">
                  <dt class="col-sm-6">
                    Perform at the end of order cycle
                  </dt>
                  <dd class="col-sm-6">
                    <b-form-checkbox
                      class="custom-control-success"
                      v-model="pc.is_perform_at_the_end"
                      :id="'is-perform-at-the-end-' + pc.id"
                      @change="handleProcessConfChange(pc.id, 'is_perform_at_the_end', pc.is_perform_at_the_end)"
                    >
                    </b-form-checkbox>
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-6">
                    Time delay (hours)
                  </dt>
                  <dd class="col-sm-6">
                    <b-form-input
                      placeholder="Enter hours"
                      type="number"
                      v-model="pc.time_delay"
                      :id="'time-delay-' + pc.id"
                      @change="handleProcessConfChange(pc.id, 'time_delay', pc.time_delay)"
                    />
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-5">
                    <hr>
                  </dt>
                  <dt class="col-sm-2">
                    OR
                  </dt>
                  <dt class="col-sm-5">
                    <hr>
                  </dt>
                </dl>
                <dl class="row">
                  <dt class="col-sm-6">
                    Perform at certain time every day
                  </dt>
                  <dd class="col-sm-6">
                    <b-form-timepicker
                      placeholder="Choose a time"
                      local="de"
                      v-model="pc.perform_at"
                      :id="'perform-at-' + pc.id"
                      @input="handleProcessConfChange(pc.id, 'perform_at', pc.perform_at)"
                    />
                  </dd>
                </dl>
                <div
                  v-if="pc.process_name == 'Stock synchronizations'"
                >
                  <hr>
                  <dl class="row">
                    <dt class="col-sm-12">
                      <h4><b>Source</b></h4>
                    </dt>
                  </dl>
                  <b-tabs fill pills>
                    <b-tab title="Google Sheets" active>
                      <b-card>
                        <b-form-input
                          placeholder="Google Sheets URL"
                          v-model="hubDetails.stock_sync_source.google_sheets_url"
                          @change="handleStockSyncSourceChange(hubDetails.stock_sync_source.id, 'google_sheets_url', hubDetails.stock_sync_source.google_sheets_url)"
                        />
                      </b-card>
                    </b-tab>
                    <b-tab title="FTP Server">
                      <b-card>
                        <b-input-group>
                          <b-form-input
                            placeholder="Server"
                          />
                          <b-form-input
                            placeholder="Username"
                          />
                          <b-form-input
                            placeholder="Password"
                          />
                        </b-input-group>
                      </b-card>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                v-if="pc.process_name != 'Get orders analytics'"
                @click="runProccessNow(hubDetails.id, pc.id, pc.process_name)"
              >
                Run process now
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="danger"
                :id="'delete-process-' + pc.id"
                @click="handleDeleteProcess(pc.id)"
              >
                Delete process
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BBadge,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import axios from "axios"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BBadge,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    BInputGroup,
    BInputGroupAppend,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hubDetails: {},
      availableProcesses: [],
    }
  },
  methods: {
    getHubDetails() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/" + this.$route.params.id + "/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          this.hubDetails = response.data
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    getAvailableProcesses() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/" + this.$route.params.id +
          "/available-processes/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          this.availableProcesses = response.data.results
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleHubDetailsChange(id, key, value) {
      var params = {}
      params[key] = value

      axios
        .patch(process.env.VUE_APP_ROOT_API + "/hubs/" + id + "/", params, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handleStockSyncSourceChange(id, key, value) {
      var params = {}
      params[key] = value

      axios
        .patch(process.env.VUE_APP_ROOT_API + "/hubs/stock-sync-source/" + id + "/", params, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handleCreateProcess(hub_id, proc_id) {
      var params = {}
      params["hub"] = hub_id
      params["process"] = proc_id

      axios
        .post(process.env.VUE_APP_ROOT_API + "/hubs/create-process-configuration/", params, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .then(() => {
          this.getHubDetails()
          this.getAvailableProcesses()
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handleProcessConfChange(id, key, value) {
      var params = {}

      if (key == "time_delay" && value == "") {
        value = null
      }

      params[key] = value

      axios
        .patch(process.env.VUE_APP_ROOT_API + "/hubs/process-configuration/" + id + "/", params, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handleDeleteProcess(pc_id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this process.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .delete(process.env.VUE_APP_ROOT_API + "/hubs/process-configuration/" + pc_id + "/", {
                headers: {
                  "Authorization": `JWT ${this.$store.state.jwt}`,
                  "Content-Type": "application/json"
                }
              })
              .then(() => {
                this.getHubDetails()
                this.getAvailableProcesses()
              })
              .catch(error => {
                if (error.response.status == 401) {
                  this.$router.push({ name: "login" })
                }
              })
          }
        })
    },
    runProccessNow(hub_id, pc_id, pc_name) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to run this process.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            let reqUrl
            if (pc_name == "Generation XML orders") {
              reqUrl = "/run-gen-xml-orders/"
            }
            else if (pc_name == "Generation PDF invoices") {
              reqUrl = "/run-generate-invoice-pdf/"
            }

            if (reqUrl) {
              var params = {}
              params["pc_id"] = pc_id

              axios
                .get(process.env.VUE_APP_ROOT_API + reqUrl, {
                  headers: {
                    "Authorization": `JWT ${this.$store.state.jwt}`,
                    "Content-Type": "application/json"
                  },
                  params: params,
                })
                .then(() => {
                  this.$router.push({ name: "hubs"  })
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$router.push({ name: "login" })
                  }
                })
            }
          }
        })
    },
  },
  computed: {
    ordersCloseAtLocale() {
      return (new Date(this.hubDetails.orders_close_at)).toLocaleString("de-DE", { timeZone: "Europe/Berlin" })
    },
  },
  created() {
    this.getHubDetails()
    this.getAvailableProcesses()
  }
}
</script>

<style>

</style>
